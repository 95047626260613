import { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import { MenuItem } from '../types/menuItem';

// Example of how to join tables that are unrelated by foreign key. menu_items
// and listing_photos can't be directly joined. Instead, we query listings since
// both menu_items and listing_photos have a foreign key relationship to
// listings. This lets us fetch all the listing data in a single db query.

const useListingData = (listingId: string) => {
    const [listingData, setListingData] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchListingData = async () => {
            try {
                const { data, error } = await supabase
                    .from('listings')
                    .select('*, menu_items (*), listing_photos(*)')
                    .eq('id', listingId)

                if (error) {
                    throw error;
                }

                setListingData(data as any);
            } catch (error) {
                setError(error as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchListingData();
    }, [listingId]);

    return { listingData, loading, error };
};

export default useListingData;
