import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../supabase';
import { User as SupaBaseUser } from '@supabase/supabase-js';

interface AuthContextType {
  user: SupaBaseUser | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<any> = ({ children }) => {
  const [user, setUser] = useState<SupaBaseUser | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
    const { data: { user } } = await supabase.auth.getUser()
      setUser(user);
      setLoading(false);
    };

    fetchUser();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
    const { data: { user } } = await supabase.auth.getUser()
      setUser(user);
      setLoading(false);
    });

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  const value = { user, loading };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
