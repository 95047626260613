import React, { useState, useEffect } from 'react';
import * as faceapi from 'face-api.js';

interface FaceUploadProps {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const FaceUpload: React.FC<FaceUploadProps> = ({ file, setFile }) => {
  const [imageUrl, setImageUrl] = useState('/default-thumbnail.jpg'); // set this to your default thumbnail image

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;
    const imageFile = files[0];
    setFile(imageFile);

    await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');

    const image = await faceapi.bufferToImage(imageFile);
    const detection = await faceapi.detectSingleFace(image);

    if (!detection) {
      alert('No face detected in the image. Please upload a different image.');
      setFile(null);
    } else {
      // create a URL representing the file
      const url = URL.createObjectURL(imageFile);
      setImageUrl(url);
    }
  };

  // Cleanup function to revoke object URL
  useEffect(() => {
    return () => {
      if (imageUrl && imageUrl !== '/default-thumbnail.jpg') {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  return (
    <div className="flex flex-col items-center justify-center bg-white p-4 shadow-md rounded-md">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="face-upload">
        Upload your face picture:
      </label>
      <input
        type="file"
        id="face-upload"
        accept="image/*"
        onChange={onFileChange}
        className="py-2 px-3 text-grey-darkest rounded"
      />
      <div className="mt-4">
        <img src={imageUrl} alt="Uploaded face" className="h-24 w-24 object-cover rounded"/>
      </div>
    </div>
  );
};

export default FaceUpload;
