import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabase';
import styled from '@emotion/styled';

// TODO use formik

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  color: white;
  background: #0077b6;
  cursor: pointer;
`;

const Message = styled.p`
  color: #333;
  text-align: center;
`;

const StyledLink = styled.a`
  color: #0077b6;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        setLoading(true);
        const { error } = await supabase.auth.signInWithPassword({ email, password });
        if (error) {
            setMessage(`Error: ${error.message}`);
        } else {
            setMessage('Login successful!');
            navigate('/');
        }
        setLoading(false);
    };

    return (
        <Container>
            <FormContainer>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Log in</h2>
                <Input
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    id="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    onClick={handleLogin}
                    disabled={loading}
                >
                    Log In
                </Button>
                {message && (
                    <Message>{message}</Message>
                )}
                <Message>
                    New to oste?{' '}
                    <StyledLink href="/signup">
                        Sign up
                    </StyledLink>
                </Message>
            </FormContainer>
        </Container>
    );
};

export default Login;
