import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';

const Button = styled.button`${tw`bg-blue-500 text-white px-6 py-2 rounded mt-4`}`;

const HostingButton: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/host_onboarding');
  };

  return (
    <Button onClick={handleClick}>
      Start Hosting
    </Button>
  );
};

export default HostingButton;
