import { useContext } from 'react';
import { SupabaseContext } from '../providers/SupabaseContext';

export const useSupabase = () => {
  const supabase = useContext(SupabaseContext);
  if (!supabase) {
    throw new Error("Must be used within a SupabaseProvider");
  }
  return supabase;
};
