import React, { useState } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import 'tailwindcss/tailwind.css';
import { supabase } from '../supabase';
import styled from 'styled-components';
import { useAuth } from '../providers/AuthContext';
import { User } from '@supabase/supabase-js';
import { Host } from '../types/host';
import { Listing } from '../types/listing';
import ListingUploadForm from './ListingUpload';
import { v4 } from 'uuid';
import HostOnboarding from './HostOnboarding';
import MenuItemForm from './MenuItemForm';

interface FormData {
    description: string;
}

interface HostFormProps {
    onSubmit: (hostId: string) => void,
    user: User,
}

const CreateHostForm: React.FC<HostFormProps> = ({ onSubmit, user }) => {
    const initialValues: FormData = {
        description: '',
    };

    const handleSubmit = async (
        values: FormData,
        actions: FormikHelpers<FormData>
    ) => {
        const { data, error } = await supabase.from('hosts').insert({ host_description: values.description, user_id: user.id }).select();
        if (data == null) {
            throw Error('Database error creating host')
        }
        onSubmit((data[0] as Host).id);
        actions.setSubmitting(false);
    };

    return (
        <div className="max-w-md mx-auto">
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <label
                            htmlFor="description"
                            className="block text-gray-700 text-sm font-bold mb-2"
                        >
                            Description
                        </label>
                        <Field
                            type="text"
                            id="description"
                            name="description"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Submit
                        </button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};



interface ListingFormData {
    listingName: string;
    listingTitle: string; // Add the listingTitle field
    description: string;
    hasWifi: boolean;
    hasOutdoorSeating: boolean;
}

const Wrapper = styled.div`
  max-width: 32rem;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  color: #4a5568;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const Input = styled(Field)`
  appearance: none;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #4a5568;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Checkbox = styled(Field)`
  margin-right: 0.5rem;
`;

const CheckboxLabel = styled.span`
  font-size: 0.875rem;
  color: #4a5568;
`;

const SubmitButton = styled.button`
  background-color: #3b82f6;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #2563eb;
  }
`;

interface ListingFormProps {
    hostId: string,
    onSubmit: (listingId: string) => void,

}

const ListingForm: React.FC<ListingFormProps> = ({ hostId, onSubmit }) => {
    const initialValues: ListingFormData = {
        listingName: '',
        listingTitle: '',
        description: '',
        hasWifi: false,
        hasOutdoorSeating: false,
    };

    const handleSubmit = async (
        values: ListingFormData,
        actions: FormikHelpers<ListingFormData>
    ) => {

        const { data, error } = await supabase.from('listings').insert({
            id: v4(),
            listing_name: values.listingName,
            listing_title: values.listingTitle,
            description: values.description,
            host_id: hostId,
            has_wifi: values.hasWifi,
            has_outdoor_seating: values.hasOutdoorSeating,
        }).select();
        if (data == null) {
            throw Error('Database error creating host')
        }
        onSubmit((data[0] as Listing).id);
        actions.setSubmitting(false);
    };

    return (
        <Wrapper>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form>
                    <FormGroup>
                        <Label htmlFor="listingName">Listing Name</Label>
                        <Input type="text" id="listingName" name="listingName" />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="listingTitle">Listing Title</Label>
                        <Input type="text" id="listingTitle" name="listingTitle" />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="description">Description</Label>
                        <Input as="textarea" id="description" name="description" />
                    </FormGroup>
                    <FormGroup>
                        <CheckboxWrapper>
                            <Checkbox
                                type="checkbox"
                                id="hasWifi"
                                name="hasWifi"
                            />
                            <CheckboxLabel>Wi-Fi available</CheckboxLabel>
                        </CheckboxWrapper>
                    </FormGroup>
                    <FormGroup>
                        <CheckboxWrapper>
                            <Checkbox
                                type="checkbox"
                                id="hasOutdoorSeating"
                                name="hasOutdoorSeating"
                            />
                            <CheckboxLabel>Outdoor seating available</CheckboxLabel>
                        </CheckboxWrapper>
                    </FormGroup>
                    <FormGroup>
                        <SubmitButton type="submit">Submit</SubmitButton>
                    </FormGroup>
                </Form>
            </Formik>
        </Wrapper>
    );
};


interface StepData {
    hostId: string | null,
    listingId: string | null,
}
const OnboardingForm: React.FC = () => {
    const { user } = useAuth();
    const [currentStep, setCurrentStep] = useState(1);
    const [stepData, setStepData] = useState<StepData>({ hostId: null, listingId: null });


    if (user == null) {
        return <p> User must be logged in </p>;

    }
    const handleSubmit = (
    ) => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePrevStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleHost = (hostId: string) => {
        setStepData({ ...stepData, hostId });
        handleSubmit()
    };
    const handleListing = (listingId: string) => {
        setStepData({ ...stepData, listingId });
        handleSubmit();
    };
    const handleListingPhotos = () => {
        handleSubmit();
    };
    const handleMenuItems = () => {
        handleSubmit();
    };

    const renderForm = () => {
        switch (currentStep) {
            case 1:
                return <CreateHostForm onSubmit={handleHost} user={user} />;
            case 2:
                return <ListingForm onSubmit={handleListing} hostId={stepData.hostId!} />;
            case 3:
                return <ListingUploadForm onSubmit={handleListingPhotos} listingId={stepData.listingId!} />;
            case 4:
                /* return <MenuItemForm onSubmit={handleListingPhotos} listingId={stepData.listingId!} />; */
                return <MenuItemForm onSubmit={handleMenuItems} listingId={stepData.listingId!}  />;
            case 5:
                return <HostOnboarding />;
            default:
                return <p> Error </p>;
        }
    }
    return renderForm();
}

export default OnboardingForm;
