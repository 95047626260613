import React, { useState } from 'react';
import { supabase } from '../supabase';  // assuming you've set up Supabase client
import { useAuth } from '../providers/AuthContext';
import tw from 'twin.macro';


const Button = tw.button`px-8 py-2 bg-blue-600 text-white rounded-lg`;


const HostOnboarding: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const user = useAuth();

    const onboardUser = async () => {
        try {
            // Get current user session
            // FIXME
            if (!user) throw new Error("User not logged in");

            // Request to create account link
            const { data, error } = await supabase.functions.invoke('create-stripe-account-link', {
                body: {}
            });

            if (data) {
                // If successful, redirect user to Stripe Connect onboarding
                console.log('response: ', data);
                window.location.href = data.url;
            } else {
                throw new Error("Failed to create account link");
            }
        } catch (error) {
            console.error('Error with Stripe Connect onboarding:', error);
        } finally {
            setLoading(false);
        }
    };

    return (<Button disabled={loading} onClick={onboardUser}> Connect Stripe account</Button>)
}

export default HostOnboarding;
