import React from 'react';
import styled from 'styled-components';
import { Host } from '../types/host';
import HostCard from './HostCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const TableContainer = styled.div`
  max-width: 1120px;
  margin: auto;
  padding: 0 16px;
`;


interface HostTableProps {
    hosts: any[];
    /* username: string; first_name: string; last_name: string */
}

const HostTable: React.FC<HostTableProps> = ({ hosts }) => {
    const [items, setItems] = React.useState<Array<Host>>(hosts.slice(0, 10));
    const [hasMore, setHasMore] = React.useState(hosts.length > 10);

    const fetchMoreData = () => {
        if (items.length >= hosts.length) {
            setHasMore(false);
            return;
        }

        // a fake async api call like which sends
        // 10 more records in 1.5 secs
        /* setTimeout(() => {
    *   setItems(items.concat(hosts.slice(items.length, items.length + 10)));
    * }, 1500); */
    };
    console.log(hosts)

    return (
        <TableContainer>
            <InfiniteScroll
                dataLength={items.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >
                {items.map((host) => (
                    <HostCard key={host.id} host={host} />
                ))}
            </InfiniteScroll>
        </TableContainer>
    );
};

export default HostTable;
