import React, { Fragment } from 'react';
import tw from 'twin.macro';
import useMenuItems from '../hooks/useMenuItems';
import { MenuItem as MenuItemType } from '../types/menuItem';
import Menu from './Menu';
import useListingData from '../hooks/useListingData';

const Container = tw.div`flex flex-col items-center justify-center min-h-screen bg-gray-100 py-2`;
const ListingInfoCard = tw.div`bg-white rounded-lg p-4 shadow-lg mb-4 w-full`;
const ListingName = tw.h2`text-2xl font-bold`;
const ListingAddress = tw.p`mt-2 text-gray-600`;
const ListingHours = tw.p`mt-2 text-gray-600`;
const ListingRating = tw.p`mt-2 text-gray-600`;
const OrderBar = tw.div`w-full flex justify-center items-center mt-4`;
const OrderButton = tw.button`px-8 py-2 bg-blue-600 text-white rounded-lg`;

interface ListingProps {
    listingId: string
}

const Listing: React.FC<ListingProps> = ({ listingId }) => {
    const { listingData, loading, error } = useListingData(listingId);

    /* const listingPhotos = listingData.listing_photos;
* const menuItems = listingData.menu_items; */
    const listing = {
        name: 'Cafe',
        address: '123 Main St',
        hours: '9am - 5pm',
        rating: 4.5,
        /* ..listingData.listing */
    };
    /* const menuItems = [
  *   {
  *     name: 'Coffee',
  *     description: 'Freshly brewed coffee',
  *     image_url: '/images/coffee.jpg', // replace with actual image path
  *     quantity: 0,
  *   },
  *   // add more items as needed
  * ]; */

    return (
        <Container>
            {loading &&
                (
                    <Fragment>
                        <ListingInfoCard>
                            <ListingName>{listing.name}</ListingName>
                            <ListingAddress>{listing.address}</ListingAddress>
                            <ListingHours>Hours: {listing.hours}</ListingHours>
                            <ListingRating>Rating: {listing.rating}/5</ListingRating>
                        </ListingInfoCard>

                        {/* <Menu menuItems={menuItems ?? [] as MenuItemType[]} /> */}

                        <OrderBar>
                            <OrderButton>Order</OrderButton>
                        </OrderBar>
                    </Fragment>

                )
            }        </Container>
    );
};

export default Listing;
