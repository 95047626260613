import { useState, useEffect } from 'react';
import { supabase } from '../supabase';

const PAGE_SIZE = 10;  // Set the number of visits per page

const useVisits = (userId: string) => {
  const [visits, setVisits] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const { data, error, count } = await supabase
          .from('visits')
          .select('*', { count: 'exact' })
          .eq('user_id', userId)
          .range(page * PAGE_SIZE, (page + 1) * PAGE_SIZE - 1);

        if (error) {
          throw error;
        }

        // If data is null, initialize as an empty array
        const newData = data || [];

        setVisits((prevVisits) => [...prevVisits, ...newData]);
        setHasMore((count || 0) > (page + 1) * PAGE_SIZE);
      } catch (error) {
        // Make sure error is an instance of Error
        if (error instanceof Error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchVisits();
  }, [userId, page]);

  const loadNextPage = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return { visits, loading, error, loadNextPage, hasMore };
};

export default useVisits;
