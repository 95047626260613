import { createClient, SupabaseClient } from '@supabase/supabase-js';
import React, { createContext, ReactNode } from 'react';
import { supabase } from '../supabase';

export const SupabaseContext = createContext<SupabaseClient | null>(null);

interface SupabaseProviderProps {
  children: ReactNode;
}

export const SupabaseProvider: React.FC<SupabaseProviderProps> = ({ children }) => {
  return (
    <SupabaseContext.Provider value={supabase}>
      {children}
    </SupabaseContext.Provider>
  );
};
