import React from 'react';
import styled from 'styled-components';

interface InputFieldProps {
  id: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledInput = styled.input`
  appearance: none;
  border-radius: 0;
  position: relative;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  placeholder: #6b7280;
  color: #1f2937;
  outline: none;
  &:focus {
    ring-color: #6366f1;
    border-color: #6366f1;
    z-index: 10;
  }
  @media (min-width: 640px) {
    font-size: 0.875rem;
  }
`;

const InputField: React.FC<InputFieldProps> = ({ id, type, placeholder, value, onChange }) => {
  return (
    <div className="mb-4">
      <label htmlFor={id} className="sr-only">{placeholder}</label>
      <StyledInput
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
