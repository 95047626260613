import React from 'react';
import styled from 'styled-components';
import { Rating } from 'react-simple-star-rating';
import { Host } from '../types/host';
import Listing from './Listing';

const Card = styled.div`
  display: flex;
  background-color: #fff;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
`;

const CardBody = styled.div`
  padding: 16px;
`;

const HostName = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Description = styled.p`
  color: #718096;
  margin-bottom: 16px;
`;

const OrderButton = styled.button`
  background-color: #667eea;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  &:hover {
    background-color: #5a67d8;
  }
`;

const Thumbnail = styled.img`
  flex-shrink: 0;
  width: 192px;
  height: 192px;
  object-fit: cover;
`;

interface HostProps {
    host: any;
}

// TODO host disntace: x KM / y Miles from you
const HostCard: React.FC<HostProps> = ({ host }) => {
    /* const thumbnailUrl = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${host.latitude},${host.longitude}&fov=80&heading=70&pitch=0&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`; */

    return (
        <Card>
            {/* <Thumbnail src={thumbnailUrl} alt={host.description} /> */}
            <CardBody>
                <HostName>{host.users.first_name} {host.users.last_name}</HostName>
                {/* <Rating
                    initialValue={rating}
                    size={20}
                    readonly={true}
                /> */}
                <Description>{host.host_description}</Description>
                <OrderButton>Order</OrderButton>
                <Listing listingId='42138769-a25d-4e56-9d9b-617adfd574bb' />
            </CardBody>
        </Card>
    );
};

// TODO round host rating

export default HostCard;
