import styled from 'styled-components';
import { HiEye, HiEyeOff } from "react-icons/hi";
import React, { useState } from 'react';

const PasswordInput = styled.input`
  appearance: none;
  border: 1px solid #d2d6dc;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0.25rem;
`;

const PasswordIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

interface PasswordFieldProps {
  id: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PasswordField: React.FC<PasswordFieldProps> = ({ id, placeholder, value, onChange }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <PasswordInput
        id={id}
        type={visible ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <PasswordIcon onClick={() => setVisible(!visible)}>
        {visible ? <HiEyeOff /> : <HiEye />}
      </PasswordIcon>
    </div>
  );
};

export default PasswordField;
