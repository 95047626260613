import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import styled from 'styled-components';
import HostTable from './HostTable';
import Layout from './Layout';
import { supabase } from '../supabase';
import { Host } from '../types/host';
import ListingUploadForm from './ListingUpload';
import OnboardingForm from './CreateListing';
import MenuItemForm from './MenuItemForm';
import { v4 as uuidv4 } from 'uuid';

const MapContainer = styled.div`
  height: 67vh;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const HomeContainer = styled.div`
  max-width: 1120px;
  margin: auto;
  padding: 0 16px;
  display: grid;
  grid-template-rows: 1fr 2fr; // Split the container into thirds
  height: 100vh;
`;

const HostTableContainer = styled.div`
  height: 33vh;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;

const Title = styled.h1`
  font-size: 1.875rem;
  font-weight: 600;
  color: #4a5568;
`;

const HostGrid = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
`;

const HostCard = styled.div`
  background-color: #fff;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 192px;
  object-fit: cover;
`;

const HostDetails = styled.div`
  padding: 24px;
`;

const Description = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  color: #4a5568;
`;

const DetailText = styled.p`
  color: #718096;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const FilterButton = styled.button`
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

// from https://icons.getbootstrap.com/icons/cup/
const coffeeCupSvg = `data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-cup' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M.11 3.187A.5.5 0 0 1 .5 3h13a.5.5 0 0 1 .488.608l-.22.991a3.001 3.001 0 0 1-1.3 5.854l-.132.59A2.5 2.5 0 0 1 9.896 13H4.104a2.5 2.5 0 0 1-2.44-1.958L.012 3.608a.5.5 0 0 1 .098-.42Zm12.574 6.288a2 2 0 0 0 .866-3.899l-.866 3.9ZM1.124 4l1.516 6.825A1.5 1.5 0 0 0 4.104 12h5.792a1.5 1.5 0 0 0 1.464-1.175L12.877 4H1.123Z'/%3E%3C/svg%3E`;

const mapStyles = [
    {
        featureType: "poi",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
];

const Home: React.FC = () => {
    // Replace this dummy data with actual data from your database
    // TODO rename userLocation?
    const [hosts, setHosts] = useState<Host[]>([]);
    /* const [currentLocation, setCurrentLocation] = useState(center); */
    const [loading, setLoading] = useState(true);

    const [center, setCenter] = useState({ lat: 40.730610, lng: -73.935242 });
    const [isFilterMenuVisible, setFilterMenuVisible] = useState(false);

    /* const initialMenuItem = {
*     id: uuidv4(),
*     image_url: 'https://example.com/image.jpg',
*     name: 'Menu Item Name',
*     price: 10.0,
*     description: 'This is a sample description of a menu item',
*     created_at: new Date().toISOString(),
*     updated_at: new Date().toISOString(),
*     listing_id: 'listing_id'
* }; */
    const getHosts = async () => {
        const { data: hosts, error } = await supabase.from('hosts').select('*, users (id, username, first_name, last_name) ');
        /* setHosts(hosts.map(v=> ({host: })) as Host[]) */
        setHosts(hosts as any[])
        setLoading(false)
    }


    useEffect(() => { getHosts() }, [])
    /* const hosts = mockHostData; */
    return (
        <Layout>
            <HomeContainer>
                <HostTableContainer>
                    {loading ? (<p> Loading... </p>) : <HostTable hosts={hosts} />}
                </HostTableContainer>
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}>
                    <MapContainer>
                        <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} zoom={13} center={center} />
                    </MapContainer>
                </LoadScript>
                <SearchBarContainer>
                    <SearchBar placeholder="Search by location" />
                    <FilterButton onClick={() => setFilterMenuVisible(!isFilterMenuVisible)}>
                        Filter
                    </FilterButton>
                </SearchBarContainer>
                {/* <MenuItemForm  /> */}
                {/* {filterVisible && <FilterMenu handleFilterApply={handleFilterApply} />} */}
                {/* <div className="w-full h-64 bg-gray-100 flex justify-center items-center">
                    <label
                        htmlFor="upload"
                        className="relative p-4 bg-white rounded-lg shadow-lg cursor-pointer hover:bg-gray-200"
                    >
                        <span className="text-gray-600">Drag and drop files here</span>
                        <input
                            id="upload"
                            type="file"
                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        />
                    </label>
                </div> */}
            </HomeContainer>
        </Layout>
    );
};

export default Home;
