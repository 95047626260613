import { User } from '@supabase/supabase-js';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import HostOnboarding from './components/HostOnboarding';
import Listing from './components/Listing';
import Logbook from './components/Logbook';
import Login from './components/Login';
import Menu from './components/Menu';
import Orders from './components/Orders';
import PrivateRoute from './components/PrivateRoute';
import Profile from './components/Profile';
import SignUp from './components/SignUp';
import { AuthProvider } from './providers/AuthContext';
import { SupabaseProvider } from './providers/SupabaseContext';
import { Order } from './types/order';
import OnboardingForm from './components/CreateListing';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from './stripe';

const App: React.FC = () => {
    return (
        <Elements stripe={stripePromise}>
            <SupabaseProvider>
                <AuthProvider>
                    <Routes>
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/login" element={<Login />} />
                        {/* <Route path="/profile" element={<Profile />} /> */}
                        <Route path="/logbook" element={<PrivateRoute><Logbook user={undefined as never} /> </PrivateRoute>} />
                        <Route path="/host_onboarding" element={<OnboardingForm />} />
                        {/* <Route path="/menu" element={<Menu />} /> */}
                        {/* <Route path="/listing" element={<Listing />} /> */}
                        <Route path="/" element={<PrivateRoute> <Home /> </PrivateRoute>} />
                        <Route path="/" element={<PrivateRoute> <Home /> </PrivateRoute>} />
                    </Routes>
                </AuthProvider>
            </SupabaseProvider>
        </Elements>
    );
};

export default App;
