import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { supabase } from '../supabase';
/* import { stripePromise } from '../stripe'; */
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useAuth } from '../providers/AuthContext';

const Button = tw.button`bg-blue-500 text-white px-6 py-2 rounded mt-4`;

const IdentityVerificationButton: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useAuth();

    const handleClick = async () => {
        const { data, error } = await supabase.functions.invoke('create-stripe-verification-session', {
            body: {}
        });
        if (data) {
            console.log('response: ', data);
            const identityVerification = await stripe?.verifyIdentity(data.client_secret);
            return identityVerification;



        } else {
            throw new Error("Failed to create verification session");
        }
    };

    return (
        <Button onClick={handleClick}>
            Verify Identity
        </Button>
    );
};

export default IdentityVerificationButton;
