import React, { useState } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { supabase } from '../supabase';
import { stripePromise } from '../stripe';
import FaceUpload from './FaceUpload';
import styled from '@emotion/styled';
import zxcvbn from 'zxcvbn';
import InputField from './InputField';
import PasswordField from './PasswordField';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// TODO use formik

const SignUpContainer = styled.div`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
`;

const SignUpCard = styled.div`
    max-width: 400px;
    width: 100%;
    padding: 24px;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Heading = styled.h2`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 12px;
`;

const SignUpButton = styled.button`
    cursor: pointer;
    background-color: #667eea;
    color: white;
    font-weight: 500;
    height: 45px;
    border: none;
    border-radius: 4px;
    &:hover {
        background-color: #5a67d8;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }
`;

const CardElementContainer = styled.div`
  height: 40px;
  display: grid;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 12px;
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  color: #424770;

  input {
    border: none;
    background-color: transparent;
    height: 40px;
    width: 100%;
    padding: 10px 12px;
  }
`;

const SignUp: React.FC = () => {
    return (
        <SignUpContainer>
            <Elements stripe={stripePromise}>
                <SignupForm />
            </Elements>
        </SignUpContainer>
    );
};

const SignupForm: React.FC = () => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('')

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('')

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [passwordStrength, setPasswordStrength] = useState(0);
    const [repeatPassword, setRepeatPassword] = useState('');

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [repeatPasswordVisible, setRepeatPasswordVisible] = useState(false);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const [file, setFile] = useState<File | null>(null);

    const stripe = useStripe();
    const elements = useElements();

    const handlePasswordCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRepeatPassword(e.target.value);
        if (password !== e.target.value) {
            setPasswordError("Passwords do not match.");
        } else {
            setPasswordError("");
        }
    };

    const handleSignUp = async (event: React.FormEvent) => {
        // This prevents the default form action
        event.preventDefault();

        if (passwordError) {
            return;
        }

        setLoading(true);

        // Call handleCardSubmit first
        const cardSubmitResult = await handleCardSubmit(event);

        // If there's an error in creating a payment method, stop sign up and log the error
        if (cardSubmitResult.error) {
            setLoading(false);
            console.error("Error creating payment method:", cardSubmitResult.error);
            return;
        }

        // Create a Stripe customer and attach the payment method
        const { data: customerData, error: customerError } = await supabase.functions.invoke('create-stripe-customer', {
            body: {
                payment_method: cardSubmitResult.paymentMethod?.id,
                email: email,
                name: `${firstName} ${lastName}`,
                phone: phoneNumber,
            },
        })

        // If there's an error in creating a customer, stop sign up and log the error
        if (customerError) {
            setLoading(false);
            console.error("Error creating customer:", customerError);
            return;
        }

        const { data: faceImageData, error: faceImageError } = await handleFaceImageUpload(file);

        if (faceImageError) {
            setLoading(false);
            console.error("Error uploading face image:", faceImageError);
            return;
        }

        // Continue with sign up if payment method creation and customer creation was successful
        const { data: signupData, error: signupError } = await supabase.auth.signUp({
            email,
            password,
        });

        console.log('auth user: ', signupData?.user);
        console.log('customer data: ', customerData);

        if (signupData?.user) {
            // TODO catch errors
            await supabase.from("users").insert([
                {
                    id: signupData?.user.id,
                    first_name: firstName,
                    last_name: lastName,
                    email_address: email,
                    phone_number: phoneNumber,
                    stripe_customer_id: customerData.customer.id, // Add stripe_payment_method_id
                    username: username,
                },
            ]);
        }

        navigate("/");

        if (signupError) {
            console.error("Error signing up:", signupError);
        }

        setLoading(false);
    };

    const handleFaceImageUpload = async (file: File | null) => {
        if (file === null) {
            setMessage(`Error: No face image provided`);
            return { error: message };
        }

        const fileExt = file.name.split('.').pop();
        const fileName = `public/${uuidv4()}.${fileExt}`;

        console.log('filename', fileName);
        const { data, error } = await supabase.storage
            .from('user_photos')
            .upload(fileName, file);

        if (error) {
            setMessage(`Error: ${error.message}`);
            return { error: error.message };
        } else {
            setMessage('Face image uploaded successfully.');
            console.log(data);
        }
        setLoading(false);
        return { error: null, data }; // Return paymentMethod if there's no error
    };

    const handleCardSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return { error: "Stripe library is not loaded properly", paymentMethod: null };
        }

        setLoading(true);
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement!,
        });

        if (error) {
            setMessage(`Error: ${error.message}`);
            return { error: error.message };
        } else {
            setMessage('Payment method created successfully.');
            console.log(paymentMethod);
        }
        setLoading(false);
        return { error: null, paymentMethod }; // Return paymentMethod if there's no error
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setPasswordStrength(zxcvbn(e.target.value).score);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailError(emailRegex.test(e.target.value) ? "" : "Email address is not well-formed");
    };

    return (
        <SignUpContainer>
            <SignUpCard>
                <Heading>
                    Sign up
                </Heading>
                <InputField id="firstName" type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <InputField id="lastName" type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <InputField id="username" type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                <InputField id="email" type="email" placeholder="Email" value={email} onChange={(e) => handleEmailChange(e)} />
                {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                <InputField id="phoneNumber" type="tel" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

                <PasswordField id="password" placeholder="Password" value={password} onChange={(e) => handlePasswordChange(e)} />
                <PasswordStrengthBar password={password} />
                <PasswordField
                    id="repeatPassword"
                    placeholder="Repeat Password"
                    value={repeatPassword}
                    onChange={(e) => handlePasswordCheck(e)}
                />
                {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
                <FaceUpload file={file} setFile={setFile} />
                <CardElementContainer>
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                </CardElementContainer>
                <SignUpButton onClick={handleSignUp} disabled={loading}>
                    Sign Up
                </SignUpButton>
                {message && (
                    <ErrorMessage>{message}</ErrorMessage>
                )}
            </SignUpCard>
        </SignUpContainer>
    );
};

export default SignUp;
