import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import useVisits from '../hooks/useVisits';
import { Visit as VisitType } from '../types/visit';
import { useAuth } from '../providers/AuthContext';
import { User as SupabaseUser } from '@supabase/supabase-js';
import useListing from '../hooks/useListing';
import useOtherVisitsOnSameDay from '../hooks/useOtherVisitsOnSameDay';
import { useSupabase } from '../hooks/useSupabase';
import { Listing as ListingType} from '../types/listing';
import { supabase } from '../supabase';

const LogbookContainer = tw.div`flex flex-col space-y-4`;
const VisitCard = tw.div`flex flex-col p-4 bg-white rounded-lg shadow-lg`;
const VisitTitle = tw.h2`text-xl font-bold`;
const VisitDate = tw.p`text-gray-600`;
const OtherVisitorsTitle = tw.h3`mt-4 text-lg font-semibold`;
const OtherVisitorsList = tw.ul`mt-2 space-y-2`;
const OtherVisitor = tw.li``;

interface VisitProps { visit: VisitType }

const Visit: React.FC<VisitProps> = ({ visit }) => {
    const [listing, setListing] = useState<ListingType | null>(null);
    const [otherVisits, setOtherVisits] = useState<Array<{ visitorName: string, visit: VisitType }>>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        const fetchVisitData = async () => {
            try {
                const { data: listingData, error: listingError } = await supabase
                    .from('listings')
                    .select('*')
                    .eq('id', visit.listing_id)
                    .single();

                if (listingError) throw listingError;

                const { data: visitData, error: visitError } = await supabase
                    .from('visits')
                    .select('*, users:name')
                    .eq('listing_id', visit.listing_id)
                    .neq('user_id', visit.user_id)
                    .gte('arrival', visit.arrival)
                    .lte('departure', visit.departure);

                if (visitError) throw visitError;

                setListing(listingData as ListingType);
                setOtherVisits(visitData.map(v => ({ visitorName: v.users.name, visit: v as VisitType })));
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchVisitData();
    }, [visit]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data: {error.message}</p>;
    if (!listing) return null;

    return (
        <VisitCard>
            <VisitTitle>{listing.listing_name}</VisitTitle>
            <VisitDate>{visit.arrival}</VisitDate>
            <OtherVisitorsTitle>Other visitors:</OtherVisitorsTitle>
            <OtherVisitorsList>
                {otherVisits.map((visit, index) => (
                    <OtherVisitor key={index}>{visit.visitorName}</OtherVisitor>
                ))}
            </OtherVisitorsList>
        </VisitCard>
    );
};

/* const Visit: React.FC<VisitProps> = ({ visit }) => {
*     useEffect(() => {
*         const fetchVisitData = async () => {
*             let { data: hosts, error } = await supabase
*                 .from('hosts')
*                 .select(`
*     some_column,
*     listings (
*       listing_id
*       listing_name
*     )
*   `)
*
*
*         }
*     }
*
*     const { listing, loading: visitLoading, error: visitError } = useListing(visit.listing_id);
*     const { otherVisits, loading: otherVisitsLoading, error: otherVisitsError } = useOtherVisitsOnSameDay(visit);
*
*     // If listing is loading, return loading indicator
*     if (visitLoading || otherVisitsLoading) return <p>Loading...</p>;
*
*     // If there was an error fetching the listing, display error message
*     if (visitError || otherVisitsError) return <p>Error loading listing: {visitError?.message ?? '' + otherVisitsError?.message ?? ''}</p>;
*
*     // If listing is null (after loading), render nothing
*     if (!listing) return null;
*
*     return (
*         <VisitCard>
*             <VisitTitle>{listing.listing_name}</VisitTitle>
*             <VisitDate>{visit.arrival}</VisitDate>
*             <OtherVisitorsTitle>Other visitors:</OtherVisitorsTitle>
*             <OtherVisitorsList>
*                 {otherVisits.map((visitor, index) => (
*                     <OtherVisitor key={index}>{visitor}</OtherVisitor>
*                 ))}
*             </OtherVisitorsList>
*         </VisitCard>
*     );
* }; */

interface LogbookProps { user: SupabaseUser }

const Logbook: React.FC<LogbookProps> = ({ user }) => {
    const { visits, loading, error, loadNextPage, hasMore } = useVisits(user.id);

    useEffect(() => {
        if (!loading && hasMore) {
            loadNextPage();
        }
    }, [loading, hasMore, loadNextPage]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <LogbookContainer>
            {visits.map((visit, index) => (
                <Visit key={index} visit={visit} />
            ))}
        </LogbookContainer>
    );
};

export default Logbook;
