import React, { useState } from 'react';
import tw from 'twin.macro';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useSupabase } from '../hooks/useSupabase';
import { MenuItem as MenuItemType } from '../types/menuItem';
import { v4 as uuidv4 } from 'uuid';

const FormContainer = tw.div`max-w-md w-full space-y-8`;
const FormTitle = tw.h2`mt-6 text-center text-3xl font-extrabold text-gray-900`;
const FormInput = tw(Field)`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`;
const FormTextarea = tw(Field)`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm h-24`;
const FormLabel = tw.label`block text-sm font-medium text-gray-700`;
const SubmitButton = tw.button`relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`;


interface MenuItemFormProps {
    initialValues?: MenuItemType,
    onSubmit: () => void,
    listingId: string,

}
const MenuItemForm: React.FC<MenuItemFormProps> = ({ onSubmit: propsOnSubmit , initialValues, listingId }) => {
    if (initialValues == null) {
        initialValues = {
            id: uuidv4(),
            image_url: 'https://example.com/image.jpg',
            name: 'Menu Item Name',
            price: 10.0,
            description: 'This is a sample description of a menu item',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            listing_id: 'listing_id'
        };
        console.log("Here")
    }
    const supabase = useSupabase();
    const [file, setFile] = useState<File | null>(null);

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        image_url: Yup.string().required('Required'),
        price: Yup.number().required('Required'),
    });

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files?.length) {
            setFile(event.currentTarget.files[0]);
        }
    };

    const onSubmit = async (values: Partial<MenuItemType>) => {
        // use supabase client to upload the file and get back a URL
        if (!file) {
            console.log('No file selected');
            return;
        }
        const { data: uploadedFile, error: uploadError } = await supabase.storage
            .from('menu_item_photos')
            .upload(file.name, file);

        if (uploadError) {
            console.log('Error uploading file:', uploadError);
            return;
        }

        // include the URL of the uploaded file in the values to save
        const itemToSave = {
            ...values,
            image_url: uploadedFile.path,
        };

        // Use the Supabase client to add or update menu item in your database
        const { data, error } = await supabase.from('menuItems').upsert(values);

        if (error) {
            console.log('Error creating or updating menu item:', error);
        } else {
            console.log('Menu item created or updated successfully:', data);
        }
        propsOnSubmit()
    };

    return (
        <FormContainer>
            <FormTitle>Add or Update Menu Item</FormTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <Form>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <FormInput id="name" name="name" type="text" autoComplete="name" />

                    <FormLabel htmlFor="description">Description</FormLabel>
                    <FormTextarea id="description" name="description" as="textarea" />

                    <FormLabel htmlFor="image_url">Image Upload</FormLabel>
                    <input id="image_url" name="image_url" type="file" onChange={onFileChange} />

                    <FormLabel htmlFor="price">Price</FormLabel>
                    <FormInput id="price" name="price" type="number" step="0.01" />

                    <SubmitButton type="submit">Submit</SubmitButton>
                </Form>
            </Formik>
        </FormContainer>
    );
};

export default MenuItemForm;
