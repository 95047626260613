import React from 'react';
import tw from 'twin.macro';
import HostingButton from './HostingButton';
import IdentityVerificationButton from './IdentityVerificationButton';

const HeaderContainer = tw.header`w-full bg-blue-600 text-white p-4 flex justify-between items-center`;
const HeaderTitle = tw.h1`text-lg font-bold`;
const ButtonsContainer = tw.div`flex space-x-4`; // Component for holding buttons

const Header: React.FC = () => (
    <HeaderContainer>
        <HeaderTitle>oste</HeaderTitle>
        <ButtonsContainer>
            <HostingButton />
            <IdentityVerificationButton />
        </ButtonsContainer>
    </HeaderContainer>
);

export default Header;
