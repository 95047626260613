import { useFormik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../supabase';
import { v4 } from 'uuid';

const UploadBoxWrapper = styled.div`
  width: 100%;
  height: 64px;
  background-color: #f7fafc;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.label`
  position: relative;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: #edf2f7;
  }
`;

const Button = styled.button`
  background-color: #667eea;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  &:hover {
    background-color: #5a67d8;
  }
`;

const ErrorMessage = styled.div`
  color: #e53e3e;
  margin-top: 8px;
`;

interface UploadBoxProps {
    label: string;
    name: string;
    onChange: (files: FileList) => void;
    error?: string;
}

const UploadBox: React.FC<UploadBoxProps> = ({
    label,
    name,
    onChange,
    error,
}) => {
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files != null) {
            onChange(files);
        }
    };

    return (
        <UploadBoxWrapper>
            <Label htmlFor={name}>
                <span>{label}</span>
                <input
                    id={name}
                    type="file"
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    onChange={handleFileUpload}
                />
            </Label>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </UploadBoxWrapper>
    );
};


const GalleryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

interface GalleryProps {
    images: File[];
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
    return (
        <GalleryWrapper>
            {images.map((imageUrl, index) => (
                <Image key={index} src={URL.createObjectURL(imageUrl)} alt={`Image ${index}`} />
            ))}
        </GalleryWrapper>
        // make sure to delete object url when deleting image from form
    );
};



interface ListingUploadFormProps {
    listingId: string,
    onSubmit: () => void,
}
const ListingUploadForm: React.FC<ListingUploadFormProps> = ({ listingId, onSubmit }) => {
    const [message, setMessage] = useState('');
    const formik = useFormik({
        initialValues: {
            files: [] as File[],
        },
        onSubmit: (values) => {
            // Replace this with your actual submission logic
            submitForm(values);
        },
    });

    const submitForm = async (values: { files: File[] }) => {
        try {

            const files = await Promise.all(values.files.map(async (file: File) => {
                const fileExt = file.name.split('.').pop();
                const fileName = `${v4()}.${fileExt}`;
                /* return {fileName, file}; */
                /* }
    * ); */

                /* for (const f in files) { */
                const { data, error } = await supabase.storage
                    .from('listing_photos')
                    .upload(fileName, file);
                console.log('data', data)
                return { id: v4(), url: data, is_primary_photo: false, listing_id: listingId };
            }
            ));
            console.log(files)


            const { error } = await supabase.from('listing_photos').insert(files);
            onSubmit()
        } catch (error) {
        }
    };

    return (
        <div>
            <Gallery images={formik.values.files} />
            <form onSubmit={formik.handleSubmit}>
                <UploadBox
                    label="Upload Files"
                    name="files"
                    onChange={(files) => {
                        formik.setFieldValue('files', formik.values.files.concat(Array.from(files)));
                    }
                    }
                />
                <Button type="submit">
                    Submit
                </Button>
            </form>
        </div>
    );
};
export default ListingUploadForm;



