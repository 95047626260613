import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/AuthContext'; // Assuming you have an AuthContext

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<any> = ({ children, ...props }) => {
  let { user } = useAuth();
  let location = useLocation();

  return user
  // pass props PROPerly
       ? children // Passing user as a prop
    : <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoute;
