import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Header from './Header';

const LayoutContainer = styled.div`${tw`flex flex-col min-h-screen`}`;
const MainContent = styled.main`${tw`p-4 flex-grow`}`;

// The layout component
const Layout: React.FC<any> = ({ children }) => (
  <LayoutContainer>
    <Header />
    <MainContent>
      {children}
    </MainContent>
    {/* Add a footer here if you want */}
  </LayoutContainer>
);

export default Layout;
